/*Customizable Area Start*/
.aplication_form_main{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
    border-radius: 20px;
    width: 80%;
  }

  .required_field_style{
    border: 1px solid red !important;
  }
.required_message{
  color: red;
  font-size: 18px;
}
  .aplication_form_main .card-custom{
    border: 1px solid #d6d6d6;
    padding:16px;
    width:100%;
  }

  .button_one{
    background: "#f00768";
    border-color: "#f00768";
    border-radius: '5px';
    margin-bottom: "20px";
    cursor: 'pointer';
  }
  .sectionHeader{
    text-align: center;
  }

  .sectionHeaderDiv{
    display: flex;
    justify-content: center;
  }
  
  label {
    color: rgb(47, 38, 38);
    font-weight: 400;
  }
  .btn_primary{
    background: #f00768 !important;
    color: #fff!important;
    border-color: #f00768!important;
    border-radius: 5px!important;
    margin-bottom: 20px!important;
    cursor: pointer !important;
  }  
  .btn_secondary{
    background: white !important;
    color: #fff!important;
    border-color: #f00768!important;
    border-radius: 5px!important;
    margin-bottom: 20px!important;
    cursor: pointer !important;
  }
  .form-definition{
    display: flex;
  }
  .form-definition-title{
    font-weight:600;
    padding-right:5px;
  }
  .aplication_form_main .card-custom{
    border: 1px solid #d6d6d6;
    padding:16px;
    width:100%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

/*Customizable Area End*/