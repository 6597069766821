.ant-modal-close-x {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    font-size: 16px;
    font-style: normal;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
}
