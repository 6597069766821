.organization-dashboard-main{
    /* height: 100%; */
    /* align-items: center; */
    /* margin: 50px auto; */
}
.organization-dashboard-main .MuiPaper-elevation1 {
    box-shadow: none;
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 5px;
}
.Categories_table {
    margin: 25px auto;
}
.table-border-right{
    border-right: 1px solid rgba(224, 224, 224, 1);
    font-weight: 600;
}
.progressbar .ant-progress-success-bg, .ant-progress-bg {
    position: relative;
    background-color: rgb(230, 37, 126) !important;
    border-radius: 100px;
    transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.progressbar{
    display: flex;
    margin: 25px 0px;
}
.progressbar .ant-progress-line {
    position: relative;
    width: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
}
.modal-icon-size{
    width: 40px;
    margin-right: 5px;
    cursor: pointer;

}
.dropzone-main{
    max-height: 235px;
    overflow-y: auto;
}
.dropzone-main li{
    text-align: start;

}
.categories-table-header .MuiTableCell-root{
    font-weight: 600 !important;
}
.uploadDocumnet_list{
    display: flex;
    width: 100%;
}
.ant-progress-text {
    display: inline-block;
    position: absolute ;
    width: 2em;
    bottom: -1px;
    right: 0px;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 1em;
    line-height: 1;
    white-space: nowrap;
    text-align: left;
    vertical-align: middle;
    word-break: normal;
}
.ant-progress-show-info .ant-progress-outer {
    margin-right: calc(-2em - 8px);
    padding-right: calc(2em + 8px);
    width: -webkit-fill-available;
} 
.progressbar1{
    display: flex;
    width: -webkit-fill-available;
    position: relative;
    width: 100%;
}
.progressbar1 .file-name{
    position: absolute;
    margin: 0px;
    top: -5px;
    left: 0px;
}
.progressbar1 .uploading{
    position: absolute;
    margin: 0px;
    bottom: -5px;
    right: 30px;
}
.progressbar1 .mb{
    position: absolute;
    margin: 0px;
    bottom: -5px;
    left: 0px;
}
.ant-progress-outer {
    display: inline-block;
    width: 100%;
    margin-right: 0;
    padding-right: 0;
}
.formtable .MuiTableCell-root {
    display: table-cell;
    padding: 16px;
    font-size: 0.875rem;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    border: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
}
.modal-close-button{
    text-align: end;
}
.congragulations-modal {
    height: auto;
    margin-top: 20px;
    margin-right:auto;
    margin-left: auto;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:25rem;
    text-align:center ;

}
.congragulations-modal .modal-button-div{
    margin-top: 20px;    
    text-align:center ;
}
.modal-button-div .btn-primary{
    margin-right: 5px;
}
.congragulations-modal .congragulations-modal-icon {
    height: 60px;
    width: 50px;
}

.congragulations-modal .condition p{
    font-size: 12px;
    margin: 0px;
    color: rgb(156, 156, 156);
}
.congragulations-modal .condition span{
    margin-right: 5px;
    color: red;
}


/* form css  */
.categoryForm-table-input .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background: #fff;
    border-bottom: 1px solid #ff4d4f;
}
.categoryForm-table-input  .ant-form-item {    
    margin-bottom: 0px !important;    
}

.categoryForm-table-input .ant-input { 
    border: 0 !important;
    border-bottom: 1px solid #d9d9d9 !important;
}

.categoryForm-table-input .ant-input:focus, .ant-input-focused {
    border-color: none !important;
    box-shadow:  none !important;
    border-right-width: 1px;
    outline: 0;
}


.categoryForm-input .ant-input {
    padding: 18.5px 14px;
}
.categoryForm-input  .ant-form-item {    
    margin-bottom: 0px !important;    
}

.upload_file_model{
    z-index: 999 !important;
}
.uploadede-img-size{
    max-width: 470px;
    min-height:150px;
}
.uploadDocumnet_list span{
    cursor: pointer;
}
.view-uploaded-doc{
    padding: 10px 0px;
text-align: center;
height: calc(100% - 120px);
overflow-y: auto;

}
.submit-form-btn p{
    color: red;
    margin: 0px;
}
.reminder-popup{
    margin: 10px auto;
    max-width: 870px;
    background: rgb(255, 237, 240);
    text-align: left;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
}
.reminder-popup .red-icon{
    color: red;
    cursor: pointer ;
}
.upload-doc-mod{
    width: 100%;
    border : 2px dashed #e6257e;
    border-radius: 10px;
    background-color: #EFEFEF;
    display: flex;
    justify-content: center;
}
.user-status {
    text-transform: capitalize ;
}
.table-footer-row{
    background-color: #C7C6FF;
}
.no-document{
    padding: 10px 15px;
}
.finalStatus_color{
    color: '#817fa6' !important;
}

.feedback-label .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
}
.feedback-label .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}


.form-definition{
    font-size: 16px;   
}
.sectionHeaderDiv h1{
    font-size: 24px;
    font-weight: 600;
}
.section-one-form #text{
    height: 55px;
    border: 1px solid #8080804d;
    border-radius: 10px;
}
.section-one-form #email{
    height: 55px;
    border: 1px solid #8080804d;
    border-radius: 10px;
}
.section-one-form .ant-select-selector{
    height: 55px !important;
    border: 1px solid #8080804d !important;
    border-radius: 10px !important;
}
.section-one-form #textarea{
    height: 100px;
    border-radius: 10px;
    border: 1px solid #8080804d;
}
.section-one-form .ant-input-number-input{
    height: 55px !important;
}
.section-one-form .ant-select-selection-search ,.ant-select-selection-item {
    display: flex !important;
    align-items: center !important;
}
.section-one-form .ant-input-number{
    border-radius: 10px !important;
    overflow: hidden !important;
}
.dynamic_table .ant-table-thead .ant-table-cell {
    background-color: #dddddd70;
    border-bottom: 1px solid #b4b4b482;
    border-right: 1px solid #b4b4b482 !important;
    font-weight: 600;
}
.dynamic_table .ant-table-thead{
height: 75px;
}
.dynamic_table .ant-table-tbody .ant-table-cell{   
height: 60px !important;
padding: 0px !important;
}

.dynamic_table .ant-input { 
border: none !important;
height: 93% !important;
width: 99%;
}

.dynamic_table{
    padding-bottom: 10px;
}
.dynamic_table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
    border: 1px solid #666 !important;
    border-radius: 5px !important;
    overflow: hidden !important;
}
.dynamic_table tr.ant-table-row.ant-table-row-level-0 > td:first-child {
    padding: 16px !important;
}
.dynamic_table .ant-table-tbody > tr > td {
    border-bottom: 1px solid #b4b4b482;
}
.dynamic_table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td{
    border-right: 1px solid #b4b4b482;
}
.communityImpactSection-header .header-section{
    height: 100px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.communityImpactSection-header .header-section h2{
    text-align: center;
    color: #fff;
}
.communityImpactSection-header .header-section h1{
    text-align: center;
    color: white;
    font-weight: bolder;
}
.formtable .MuiTableRow-head{
    background-color: #F6F6F6;
}