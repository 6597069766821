/* button{
    font-weight: 600 !important;
} */
.header-custom{
    background: black !important; 
    height: 100px;
    display:flex; 
    justify-content: center; 
    align-items:center
}
.header-custom h1{
     text-align: center; 
     color: white !important;  
     font-weight: bolder    
}
.btn-primary{
    color: #fff !important;
    background-color: #e6257e !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
}
.btn-secondary{
    color: #e6257e !important;
    background-color: #fff !important;
    border: 1px solid #e6257e !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
}
.btn-green{
    color: #fff !important;
    background-color: #34bf59 !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
}
.btn-white{
    color: #e6257e !important;
    background: white !important;
    font-weight: 600 !important;
}
.btn-disabled{
    background: rgb(250, 168, 202) !important;
    color: #fff !important;
}
.itemCente{
    display: flex;
    justify-content: center;
    align-items: center;
}
.upload-doc-list{
    padding-left:5px !important;
    margin:0px;
    min-height:250px;
    max-height:480px;
    overflow-y:auto ;
}
.ant-modal {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    pointer-events: none;
    position: relative;
    top: 200px;
    width: auto;
    max-width: calc(100vw - 32px);
    margin: 0 auto;
    padding-bottom: 24px;
}
.feedback_modal{
    width: 800px !important;
}
.registration-page-invoice .ant-card-type-inner .ant-card-body {
    padding: 5px 0px;
}
.registration-page-invoice .ant-card-head {
    min-height: 35px;
    height: 35px;
}
.registration-page-invoice .ant-card-head-wrapper{
    height: 35px;
}
.registration-page-invoice h3{
    margin: 0px;
}
.registration-categories .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #d9d9d9 !important;
}
.login-page-form{
    display: flex ;
    justify-content:center;
    align-items: start;
    height: 500px;
    width: auto;
    margin:50px 20px;
}
.modal-close-button{
    text-align: end;
}
.modal-close-icon{
    width: 10px;
    height: 12px;
    cursor: pointer;
}
.modal-heading{
    font-weight: 600 !important;
}
.registration-form input[type=number] {
    -moz-appearance: textfield;
  }
.registration-page-invoice input[type=number] {
    -moz-appearance: textfield;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  .table-border-right{
    border-right: 1px solid rgba(224, 224, 224, 1);
}
.jury_no_doc_modal{
    width: 400px !important;
}
.pink-button {
    border: none;
    color: #e2247c;
    text-decoration: underline;
    background-color: transparent;
    font-size: 1rem;
    cursor: pointer;
}

.pill {
    background-color: #e2247c;
    color: white;
    border-radius: 4px;
    padding: 4px 10px;
    margin-left: 1rem;
}